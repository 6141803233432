<template>
    <div class="preview_content" :class="{ show : modelValue }" id="workout-wizard-preview">
        <div class="header">
            <div class="section_header m-0">
                <button class="close_btn" @click="closeModal()"><i class="fas fa-long-arrow-alt-right"></i></button>
                <h2>Preview</h2>
            </div>
        </div>
        <div class="block_library">
            <div class="library_header">
                <button type="button" class="favorite" @click="handleFavouriteExercises()"><i :class="exerciseParams.is_favourite ? 'fas fa-heart' : 'far fa-heart'"></i></button>
                <div class="filter_search">
                    <input type="text" placeholder="Search..." @input="isTyping = true" v-model.trim="exerciseParams.search"/>
                    <button type="button" class="search_btn">
                        <i class="fas fa-search"></i>
                    </button>
                </div>
                <a @click="handleClearAttributeFilter()" v-if="selectedFilterTags.length">Clear Filter</a>
            </div>
            <ul class="search_tags" v-if="selectedFilterTags.length">
                <li v-for="(tag, t) in selectedFilterTags" :key="t" :class="tag.type">{{ tag.name }} <span class="delete_btn" @click="handleSelectFilterTag(tag)"><i class="fas fa-times"></i></span></li>
            </ul>
            <div class="filters">
                <div class="bulk_action" v-if="selectedExercises.length">
                    <button type="button" class="filter_item" @click="handleAddToBlock(selectedExercises, true)" v-if="(addExercisesLoader && !isBulkAdd) || (!addExercisesLoader && !isBulkAdd)">
                        <i class="fas fa-plus m-0"></i>Add to Block
                    </button>
                    <button type="button" class="filter_item" v-if="addExercisesLoader && isBulkAdd">
                        <i class="fa fa-spin fa-spinner"></i> Adding exercises
                    </button>
                </div>
                <div class="load_more" v-if="attributeTypesLoader"><img src="@/assets/images/load_more.gif">Loading</div>
                <template v-for="(attributeType, at) of attributeTypes" :key="at" >
                    <div class="filter_item pinned-type" @click.self="attributeTypeId = (attributeTypeId == attributeType.id ? 0 : attributeType.id)" v-if="attributeType.is_pinned">
                        {{ attributeType.type }} <i class="fas fa-angle-down"></i>
                        <div class="dropdown_wpr" :class="attributeTypeId == attributeType.id ? 'active' : ''">
                            <ul>
                                <li v-for="(attribute, a) of attributeType.attributes" :key="a" @click="handleSelectFilterTag(attribute)">
                                    <label class="checkbox">
                                        <span :class="{'active' : selectedFilterTags.some(att => att.id === attribute.id)}"><i class="fas fa-check"></i></span>
                                        {{ attribute.name }}
                                    </label>
                                </li>
                            </ul>
                        </div>
                    </div>
                </template>
                <template v-for="(attribute, at) of customAttributeTypes" :key="at">
                    <button class="filter_item pinned-type" @click="handleDefaultExercises(0)" v-if="attribute.is_pinned && attribute.type == 'custom'">
                        My Exercises
                    </button>
                    <button class="filter_item pinned-type" @click="handleDefaultExercises(0 , 1)" v-else-if="attribute.is_pinned && attribute.type == 'shared'">
                        Shared Exercises
                    </button>
                </template>

                <button type="button" class="favorite" @click="handleFavouriteExercises()"><i :class="exerciseParams.is_favourite ? 'fas fa-heart' : 'far fa-heart'"></i></button>
                <div v-show="!attributeTypesLoader" class="other_filter_items" @click="otherFilters = !otherFilters"><i class="fas fa-ellipsis-v"></i></div>
                <div class="attribute_bar" :class="{show : otherFilters}">
                    <h3>All Attributes <button @click="otherFilters = false;"><i class="fas fa-times"></i></button></h3>
                    <div class="toggle_btn">
                        <a v-if="allAttr" @click="openAllGrp" class="toggle_btn">Open All</a>
                        <a v-else @click="closeAllGrp" class="toggle_btn">Close All</a>
                    </div>
                    <div class="attribute_grp" v-for="(attributeType, at) in attributeTypes" :key="at">
                        <h4 @click="accFilter($event)">
                            <a :class="attributeType.is_pinned ? 'pinned' : ''" @click.stop="handleTogglePinAttribute(attributeType)"><i class="fas fa-thumbtack"></i></a>
                            {{ attributeType.type }}<i class="fas fa-chevron-down"></i>
                        </h4>
                        <ul>
                            <li v-for="(attribute, a) of attributeType.attributes" :key="a">
                                <label class="checkbox" @click="handleSelectFilterTag(attribute)">
                                    <span :class="{'active' : selectedFilterTags.some(att => att.id === attribute.id)}"><i class="fas fa-check"></i></span>
                                    {{ attribute.name }}
                                </label>
                                <button type="button" class="pointer" @click="handleDeleteWorkoutAttribute(attribute)" v-if="!attribute.is_default"><i class="fas fa-trash danger"></i></button>
                            </li>
                        </ul>
                    </div>
                    <div class="attribute_grp" v-if="exerciseParams.is_default == 0 || exerciseParams.is_shared == 1">
                        <h4>
                            <a>&nbsp;&nbsp;</a>
                            <span @click="handleDefaultExercises(1)">Thrive Exercises</span>
                        </h4>
                    </div>
                    <template v-for="(attributeType, at) in customAttributeTypes" :key="at">
                        <div class="attribute_grp" v-if="attributeType.type == 'custom' && (exerciseParams.is_default == 1 || exerciseParams.is_shared == 1)">
                            <h4>
                                <a :class="attributeType.is_pinned ? 'pinned' : ''" @click.stop="handleTogglePinAttribute(attributeType)"><i class="fas fa-thumbtack"></i></a>
                                <span @click="handleDefaultExercises(0)">My Exercises</span>
                            </h4>
                        </div>
                        <div class="attribute_grp" v-else-if="attributeType.type == 'shared' && exerciseParams.is_shared == 0">
                            <h4>
                                <a :class="attributeType.is_pinned ? 'pinned' : ''" @click.stop="handleTogglePinAttribute(attributeType)"><i class="fas fa-thumbtack"></i></a>
                                <span @click="handleDefaultExercises(0 , 1)">Shared Exercises</span>
                            </h4>
                        </div>
                    </template>
                </div>
            </div>

            <ul class="list exercises" id="exercises-container" v-if="workoutExercises.length">
                <li v-for="(exercise, i) of workoutExercises" :key="i" :class="{ selected : selectedExercises.includes(exercise.id) }">
                    <div class="action_items" :class="{ active : showContent }">
                        <label :for="`library-item-${i}`" class="checkbox">
                            <input type="checkbox" :id="`library-item-${i}`" :value="exercise.id" v-model="selectedExercises" hidden>
                            <span><i class="fas fa-check"></i></span>
                        </label>
                        <a @click="selectedExerciseIndex = i; handleAddToBlock([exercise.id])">
                            <template v-if="addExercisesLoader && !isBulkAdd && selectedExerciseIndex == i">
                                <i class="fa fa-spin fa-spinner"></i> Adding
                            </template>
                            <template v-else>
                                <i class="fas fa-plus"></i> Add to block
                            </template>
                        </a>
                    </div>
                    <div class="video_wpr exercise">
                        <div class="video-overlay" @click="showVideo = true; selectedExercise = exercise;"></div>
                        <!-- <div v-if="exercise.video_type == 1" class="player_wpr" v-html="parseEmbedCode(exercise.video_link)"></div>
                        <div v-else-if="exercise.video_type == 2" class="player_wpr" v-html="exercise.embed_code"></div> -->
                        <img :src="exercise.thumb" :alt="exercise.name">
                    </div>
                    <h6 v-tooltip="exercise.name"><label>{{ exercise.name }}</label></h6>
                </li>
                <infinite-loading class="infinite-loading" @infinite="handleWorkoutExercises" target=".exercises" :identifier="resetInfiniteWatcher" :top="false" ref="exercises-infinite-loading" />
            </ul>
            <div class="empty_section" v-else-if="!workoutExercisesLoader && !attributeTypesLoader">
                <h1 v-if="exerciseParams.attributes.length">Try using Body Part selection first, then narrow by Equipment Type to get best results.</h1>
                <h1 v-else>Looks like you don't have any exercises yet.</h1>
            </div>
            <!-- <div class="load_more" v-if="workoutExercisesLoader && !attributeTypesLoader">
                <img src="@/assets/images/load_more.gif">Loading
            </div> -->
            <div class="load_more mb-4 mt-4">
                <button type="button" class="add_exercise" v-if="workoutExercises.length"> Total Exercises: {{ exerciseParams.total ? exerciseParams.total : 0 }}</button>
            </div>
            <div class="exercise-quote-loader">
                <quote-loader v-if="workoutExercises.length == 0 && workoutExercisesLoader" />
            </div>
        </div>
    </div>
    <div class="modal secondary video_player" v-if="showVideo">
        <div class="modal_container">
            <div class="modal_header">
                <button class="close_btn" @click="showVideo = false"><i class="fas fa-times"></i></button>
            </div>
            <div class="video_wpr">
                <div v-if="selectedExercise.has_gif == 1 && selectedExercise.gif_image" class="player_wpr">
                    <img :src="selectedExercise.gif_image" :alt="selectedExercise.name">
                </div>
                <div v-else-if="selectedExercise.video_type == 1" class="player_wpr" v-html="parseEmbedCode(selectedExercise.video_link)"></div>
                <div class="player_wpr" v-else-if="selectedExercise.video_type == 2" v-html="selectedExercise.embed_code"></div>
            </div>
        </div>
    </div>
</template>

<script>
    import { mapState, mapActions } from 'vuex'

    import Swal from 'sweetalert2'
    import Helper from '@/utils/Helper'
    import VideoParser from '@/utils/VideoParser'
    import InfiniteLoading from 'v3-infinite-loading'

    export default {
        name: 'Workout Wizard Preview',

        data () {
            return {
                selectedFilterTags: [],
                selectedExercises: [],
                attributeTypeId: false,
                otherFilters: false,
                allAttr: 1,
                exerciseParams: {
                    page: 1,
                    last_page: 1,
                    per_page: 15,
                    is_default: 1,
                    search: '',
                    attributes: [],
                    is_favourite: 0,
                    is_shared: 0,
                },
                selectedExercise: {},
                showVideo: false,
                isTyping: false,
                isBulkAdd: false,
                selectedExerciseIndex: 0,
                showContent: false,
                workoutExercises: [],
                resetInfiniteWatcher: 0,
            };
        },

        props: {
            modelValue: Boolean,
            addToBlock: {
                type: Function,
                default: () => {},
            },
        },

        emits: ['update:modelValue'],

        components: {
            InfiniteLoading,
        },

        computed: {
            ...mapState({
                attributeTypes: state => state.workoutModule.workoutAttributeTypes,
                customAttributeTypes: state => state.workoutModule.workoutCustomAttributeTypes,
                attributeTypesLoader: state => state.workoutModule.workoutAttributeTypesLoader,
                workoutExercisesLoader: state => state.workoutModule.workoutExercisesLoader,
                addExercisesLoader: state => state.workoutModule.workoutBlockExerciseLoader,
            }),
        },

        watch: {
            'exerciseParams.search' () {
                const vm = this;

                vm.exerciseParams.page = 1;

                setTimeout(() => {
                    vm.isTyping = false;
                }, 1500);
            },

            isTyping (val) {
                const vm = this;

                if (!val && (vm.exerciseParams.search != null && (vm.exerciseParams.search.length >= 2 || vm.exerciseParams.search.length == 0))) {
                    vm.resetInfiniteWatcher++;
                    vm.handleWorkoutExercises(undefined, true);
                }
            },

            addExercisesLoader (val) {
                const vm = this;

                if (!val && vm.isBulkAdd) {
                    vm.selectedExercises = [];
                    vm.isBulkAdd         = false;
                }
            }
        },

        mounted () {
            const vm = this;

            document.getElementById('workout-wizard').onclick = (ev) => {
                if (!ev.target || !ev.target.classList || !ev.target.classList.length || !ev.target.classList.contains('pinned-type')) {
                    vm.attributeTypeId = 0;
                }
            }

            if (!Object.keys(vm.attributeTypes).length)  {
                vm.getWorkoutAttributeTypes();
                vm.getWorkoutCustomAttributeTypes();
            }

            vm.resetForm();
        },

        methods: {
            ...mapActions({
                getWorkoutAttributeTypes: 'workoutModule/getWorkoutAttributeTypes',
                getWorkoutCustomAttributeTypes: 'workoutModule/getWorkoutCustomAttributeTypes',
                getWorkoutExercises: 'workoutModule/getWorkoutExercises',
                deleteWorkoutAttribute: 'workoutModule/deleteWorkoutAttribute',
                updatePinAttributeType: 'workoutModule/updatePinAttributeType',
            }),

            handleAddToBlock (ids, isBulkAdd = false) {
                const vm = this;

                vm.isBulkAdd = isBulkAdd ? true : false;

                vm.addToBlock(ids);
            },

            accFilter (ev) {
                const vm = this;

                let el = ev.currentTarget.closest('.attribute_grp');

                if (el.classList.contains('active')) {
                    el.classList.remove('active');
                    vm.allAttr = 1;
                } else {
                    el.classList.add('active');
                    vm.allAttr = 0;
                }
            },

            openAllGrp () {
                const vm = this;

                let allParentElm = document.querySelectorAll('.attribute_grp');

                for(let i = 0; i < allParentElm.length; i++){
                    allParentElm[i].classList.add('active');
                }

                vm.allAttr = 0;
            },

            closeAllGrp () {
                const vm = this;
                let allParentElm = document.querySelectorAll('.attribute_grp');

                for (let i = 0; i < allParentElm.length; i++) {
                    allParentElm[i].classList.remove('active');
                }

                vm.allAttr = 1;
            },

            closeModal () {
                const vm = this;

                vm.otherFilters = false;

                vm.$emit('update:modelValue', false);
            },

            handleSelectFilterTag (attribute) {
                const vm = this;

                const hasFilter = vm.selectedFilterTags.findIndex((att) => att.id === attribute.id);

                if (hasFilter == -1) {
                    vm.selectedFilterTags.push({
                        id: attribute.id,
                        name: attribute.name,
                        type: vm.getAttributeFilterType(attribute.workout_attribute_type_id),
                    });

                    vm.exerciseParams.attributes.push(attribute.id);
                } else {
                    vm.selectedFilterTags.splice(hasFilter, 1);
                }

                vm.exerciseParams.attributes = vm.selectedFilterTags.map((attribute) => attribute.id);
                vm.exerciseParams.page       = 1;
                vm.resetInfiniteWatcher++;

                vm.handleWorkoutExercises(undefined, true);
            },

            getAttributeFilterType (id) {
                const vm = this;
                let type = '';

                if (id == 1) {
                    type = 'exercise'
                } else if (id == 2) {
                    type = 'part'
                } else if (id == 3 || id == 4) {
                    type = 'muscle'
                } else if (id == 5) {
                    type = 'equipment'
                } else if (id == 6) {
                    type = 'skeletal'
                } else if (id == 7) {
                    type = 'movement'
                } else if (id == 8) {
                    type = 'position'
                } else if (id == 9) {
                    type = 'contraction'
                } else if (id == 10) {
                    type = 'difficulty'
                } else if (id == 11) {
                    type = 'avatar'
                }

                return type;
            },

            resetForm () {
                const vm = this;

                vm.resetInfiniteWatcher++;

                vm.handleWorkoutExercises(undefined, true);
            },

            handleFavouriteExercises () {
                const vm = this;

                vm.exerciseParams.is_favourite = vm.exerciseParams.is_favourite ? 0 : 1;
                vm.exerciseParams.page         = 1;
                vm.resetInfiniteWatcher++;

                vm.handleWorkoutExercises(undefined, true);
            },

            handleDefaultExercises (isDefault, shared = 0) {
                const vm = this;

                if (vm.exerciseParams.is_default != isDefault) {
                    vm.exerciseParams.is_default = isDefault;
                    vm.exerciseParams.is_shared  = shared;
                    vm.exerciseParams.page       = 1;
                    vm.resetInfiniteWatcher++;

                    vm.handleWorkoutExercises(undefined, true);
                } else  if (vm.exerciseParams.is_shared != shared) {
                    vm.exerciseParams.is_default = isDefault;
                    vm.exerciseParams.is_shared  = shared;
                    vm.exerciseParams.page       = 1;
                    vm.resetInfiniteWatcher++;

                    vm.handleWorkoutExercises(undefined, true);
                }

                vm.otherFilters = false;
            },

            parseEmbedCode (url) {
                const vm = this;

                return VideoParser.embedCodeFromUrl(url);
            },

            handleDeleteWorkoutAttribute (attribute) {
                const vm = this;

                const options = Helper.swalConfirmOptions('Are you sure?', `This action is permanent. You will not be able to recover this attribute`, 'Delete');

                options.preConfirm = function () {
                                        const params = { id: attribute.id, workout_attribute_type_id: attribute.workout_attribute_type_id }

                                        return vm.deleteWorkoutAttribute(params).then((result) => {
                                            if (result) {
                                                vm.getWorkoutAttributeTypes();

                                                const index = vm.selectedFilterTags.findIndex((att) => att.id === attribute.id);

                                                if (index != -1) {
                                                    vm.selectedFilterTags.splice(index, 1);
                                                }
                                            }
                                        });
                                    };

                Swal.fire(options);
            },

            handleClearAttributeFilter () {
                const vm = this;

                vm.exerciseParams.attributes = [];
                vm.selectedFilterTags        = [];
                vm.exerciseParams.page       = 1;
                vm.resetInfiniteWatcher++;

                vm.handleWorkoutExercises(undefined, true);
            },

            handleTogglePinAttribute(attributeType) {
                const vm                = this;
                attributeType.is_pinned = attributeType.is_pinned ? 0 : 1;
                const params            = { attribute_type_id: attributeType.id, is_pinned: attributeType.is_pinned };

                vm.updatePinAttributeType(params).then((result) => {
                    if (result) {
                        vm.getWorkoutAttributeTypes();
                        vm.getWorkoutCustomAttributeTypes();
                    }
                });
            },

            async handleWorkoutExercises ($state, force = false) {
                const vm = this;

                if ($state) {
                    vm.state = $state;
                }

                if (force) {
                    vm.workoutExercises = [];
                }

                if ((vm.exerciseParams.page <= vm.exerciseParams.last_page ) || force) {
                    if (!vm.workoutExercisesLoader) {
                        await vm.getWorkoutExercises(vm.exerciseParams).then((result) => {
                            if (result.data && result.data.length) {
                                vm.exerciseParams.last_page = result.last_page;
                                vm.exerciseParams.page      = result.current_page + 1;
                                vm.exerciseParams.total     = result.total;

                                vm.workoutExercises.push(...result.data);

                                if (vm.state != undefined) {
                                    vm.state.loaded();
                                }
                            } else {
                                if (vm.state != undefined) {
                                    vm.state.complete();
                                } else {
                                    vm.exerciseParams.total = 0;
                                }
                            }
                        });
                    }
                } else {
                    if (vm.state) {
                        vm.state.complete();
                    }
                }
            },
        },
     }
</script>

<style scoped>
    .global_setting .preview_content .header{
        margin: 0;
        z-index: auto;
    }
    .filters .filter_item,
    .attribute_list {
        position: relative;
        font-size: 11px;
        line-height: 18px;
        font-weight: 400;
        color: #5a5a5a;
        cursor: pointer;
    }

    .filters .filter_item li,
    .other_filter_items li {
        font-size: 11px;
        line-height: 16px;
    }

    .time_listing li .checkbox {
        display: block;
    }

    .time_listing li .checkbox input[type=radio]:checked~.selection_box {
        border: 1px solid #b0d1ff;
        background: rgba(47, 126, 237, 0.05);
        color: #2f7eed;
    }

    .preview_content {
        width: 40%;
        position: relative;
        margin-right: -40%;
        padding: 0;
        transition: all 0.3s ease-in-out;
        background: #fff;
        z-index: 6;
    }

    .preview_content.show {
        margin-right: 0;
    }

    .preview_btn {
        padding: 10px 20px;
        border-radius: 3px;
        background: #32373b;
        color: #fff;
        font-size: 12px;
        line-height: 15px;
        font-weight: 300;
        position: fixed;
        right: -30px;
        top: 100px;
        text-transform: uppercase;
        letter-spacing: 1px;
        transform: rotate(-90deg);
        cursor: pointer;
    }

    .preview_content .close_btn {
        position: absolute;
        left: -35px;
        top: 2px;
        font-size: 14px;
    }

    .tab_sec li {
        display: flex;
        align-items: center;
    }

    .tab_sec .tag {
        padding: 2px 5px;
        border-radius: 7px;
        font-size: 8px;
        line-height: 10px;
        color: #fff;
        font-weight: 500;
        text-transform: uppercase;
        margin-left: 5px;
    }

    .cover_type {
        margin: 15px -35px 15px -35px;
        background: #fbfbfb;
        border-top: 1px solid #f1f1f1;
        border-bottom: 1px solid #f1f1f1;
        padding: 20px 35px;
    }

    .cover_type .type_list {
        justify-content: space-around;
        margin-top: 15px;
    }

    .days {
        display: flex;
        padding: 15px 0;
        gap: 7px;
    }

    .cell .days {
        border-top: 1px solid #E6E6E6;
        display: flex;
        flex-wrap: wrap;
        padding: 20px 0 20px;
    }

    .days li {
        padding: 4px 0;
        flex: 0 1 55px;
    }

    .days li label {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        cursor: pointer;
        margin: 0;
        padding: 10px 6px 12px 6px;
        background: transparent;
        border: 1px solid transparent;
        border-radius: 5px;
        transition: all 0.3s ease-in-out;
    }

    .days li label span {
        font-size: 13px;
        line-height: 16px;
        color: #5a5a5a;
        font-weight: 400;
    }

    .days li label .tick_box {
        width: 14px;
        height: 14px;
        border-radius: 50%;
        border: 1px solid #5a5a5a;
        position: relative;
        margin-top: 10px;
    }

    .days li label .tick_box:after {
        content: '';
        position: absolute;
        left: 2px;
        top: 2px;
        right: 2px;
        bottom: 2px;
        background-color: #5a5a5a;
        border-radius: 50%;
        transform: scale(0);
        transition: all 0.3s ease-in-out;
    }

    .days li label input {
        margin-right: 7px;
        opacity: 0;
    }

    .days li label.active {
        background: rgba(47, 126, 237, 0.05);
        border: 1px solid #b0d1ff;
    }

    .days li label.active .tick_box:after {
        transform: scale(1);
    }

    .sender_info {
        display: flex;
        padding-bottom: 5px;
        margin-bottom: 40px;
    }

    .sender_info img {
        width: 35px;
        height: 35px;
        margin-right: 10px;
    }

    .sender_info .info {
        margin: 0;
    }

    .sender_info .info h5 {
        font-size: 13px;
        line-height: 15px;
        font-weight: 500;
        color: #2f7eed;
        margin-bottom: 3px;
    }

    .sender_info .info h6 {
        font-size: 11px;
        line-height: 13px;
        font-weight: 500;
        color: #5a5a5a;
        margin-bottom: 3px;
    }

    .sender_info .info h6 span {
        color: #121525;
        font-weight: 600;
        padding-right: 5px;
    }

    .cell {
        width: 405px;
        padding: 60px 15px 80px;
        border-radius: 40px;
        box-shadow: 0 0 15px 1px rgba(0, 0, 0, 0.2);
        position: relative;
        margin: 30px auto;
    }

    .cell:after,
    .cell:before {
        position: absolute;
        content: "";
        width: 50px;
        background: #f2f2f2;
        left: 50%;
        transform: translateX(-50%);
    }

    .cell:before {
        height: 7px;
        border: 1px solid #d9d9d9;
        border-radius: 4px;
        top: 25px;
    }

    .cell:after {
        height: 50px;
        border: 1px solid #d9d9d9;
        border-radius: 50%;
        bottom: 15px;
    }

    .cell .content_area {
        height: 610px;
        padding: 0;
        border: 1px solid #e7e7e7;
        background: #fafafb;
        position: relative;
        display: flex;
        flex-direction: column;
    }

    .cell .msg_preview {
        padding: 20px;
        background: #fff;
        border: 1px solid #eaeaea;
        margin: 15px;
        position: relative;
    }

    .cell .sms_preview {
        padding: 15px 20px;
        background: #fff;
        border: 1px solid #eaeaea;
        margin: 15px;
        position: relative;
        border-radius: 10px;
        font-size: 12px;
        line-height: 17px;
        color: #5a5a5a;
    }

    .cell .msg_preview:after,
    .cell .sms_preview::after {
        position: absolute;
        content: '';
        background-image: url("data:image/svg+xml,%3Csvg id='Layer_1' data-name='Layer 1' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 262.83 148.44'%3E%3Cdefs%3E%3Cstyle%3E.cls-1%7Bfill:%23fff;stroke:%23c7c6c6;stroke-miterlimit:10;stroke-width:7px;%7D%3C/style%3E%3C/defs%3E%3Cpolyline class='cls-1' points='2.58 2.36 131.41 143.25 260.24 2.36'/%3E%3C/svg%3E");
        background-repeat: no-repeat;
        text-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
        width: 20px;
        height: 13px;
        left: calc(50% - 10px);
        top: 100%;
    }

    .cell .sms_preview:after {
        right: 30px;
        left: auto;
    }

    .cell .msg_preview h2 {
        font-size: 13px;
        line-height: 18px;
        margin-bottom: 15px;
        font-weight: 500;
        color: #121525;
    }

    .cell .msg_preview p,
    .cell .sms_preview p {
        font-size: 11px;
        line-height: 15px;
        margin-bottom: 10px;
        font-weight: 400;
        color: #5a5a5a;
    }

    .cell .msg_preview p span {
        font-size: 11px;
        line-height: 16px;
        padding: 0;
        font-weight: 500;
        color: #121525;
    }

    .hourly_info {
        padding: 15px;
        border: 1px solid #e9e9e9;
        border-radius: 5px;
        background: #fff;
        font-size: 11px;
        line-height: 16px;
        font-weight: 400;
        color: #757575;
        text-align: center;
    }

    .form_grp :deep(.multiselect-single-label span) {
        font-size: 13px;
    }

    :deep(.cell .dashboard_footer) {
        width: 100%;
        text-align: center;
        padding: 20px 0;
    }

    :deep(.cell .dashboard_footer ul li) {
        padding: 2px;
    }

    :deep(.cell .dashboard_footer ul li a) {
        width: 20px;
        height: 20px;
        border: 1px solid #2f7eed;
        font-size: 10px;
        color: #2f7eed;
        text-decoration: none;
    }

    :deep(.cell .dashboard_footer h4) {
        font-size: 12px;
        line-height: 14px;
        margin: 10px 0 5px;
    }

    :deep(.cell .dashboard_footer p) {
        font-size: 9px;
        line-height: 11px;
    }

    .info_bar {
        display: flex;
        flex-direction: column;
        background: #fff;
        border: 1px solid #e9e9e9;
        border-radius: 10px;
        margin: 20px;
    }

    .time {
        min-width: 150px;
        font-size: 11px;
        line-height: 14px;
        font-weight: 400;
        color: #121525;
        padding: 10px 25px;
        border-bottom: 1px solid #e9e9e9;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 10px;
    }

    .time span {
        font-size: 13px;
        line-height: 16px;
        font-weight: 500;
        display: block;
    }

    .step_bar2 {
        position: relative;
        padding: 20px 30px 10px 30px;
    }

    .step_bar2 ul {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        padding-bottom: 25px;
        position: relative;
        z-index: 1;
    }

    .step_bar2 ul li {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        position: relative;
    }

    .step_bar2 ul li h6 {
        position: absolute;
        top: 100%;
        left: 50%;
        transform: translateX(-50%);
        font-size: 11px;
        line-height: 14px;
        font-weight: 500;
        color: #121525;
        white-space: nowrap;
        text-align: center;
    }

    .step_bar2 ul li:first-child h6 {
        left: 0;
        transform: none;
        text-align: left;
    }

    .step_bar2 ul li:last-child h6 {
        right: 0;
        left: auto;
        transform: none;
        text-align: right;
    }

    .step_bar2 ul li span {
        width: 28px;
        height: 28px;
        border-radius: 50%;
        background: #fff;
        border: 3px solid #e5e5e5;
        font-size: 12px;
        font-weight: 500;
        color: #2c3e50;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 10px;
        transition: all 0.3s ease-in-out;
        cursor: pointer;
    }

    .step_bar2 .total_bar {
        position: absolute;
        content: '';
        top: 32px;
        left: 30px;
        right: 30px;
        height: 2px;
        background: #d9d9d9;
    }

    .step_bar2 .total_bar span {
        background: #a1c8ff;
        position: absolute;
        left: 0;
        height: 100%;
        transition: all 0.3s ease-in-out;
    }

    .step_bar2 ul li.active span {
        background: #2f7eed;
        color: #fff;
        border-color: #a1c8ff;
    }

    .reset-default {
        display: flex;
        justify-content: flex-end;
        font-size: 12px;
        line-height: 15px;
        color: #747474;
        font-weight: 400;
        cursor: pointer;
        position: relative;
    }

    .cover-header-with-text {
        display: flex;
    }

    .cover-header-with-text .small-cover-info {
        margin: 0;
        margin-left: 10px;
    }

    .cover-header-with-text div>small {
        font-size: 12px;
    }

    .flex-center {
        display: flex;
        align-items: center;
    }


    /* toggle_section */
    .toggle_section {
        width: 25px;
        height: 25px;
        font-size: 16px;
        color: #999;
        cursor: pointer;
        margin-left: 15px;
    }

    .toggle_section i {
        transform: rotate(0);
        transition: all 0.3s ease-in-out;
    }

    .global_setting .tabs_content .section_content {
        position: relative;
    }

    .global_setting .tabs_content .section_content .section_header {
        margin: 10px 0;
    }

    .global_setting .tabs_content .section_content:after {
        content: '';
        position: absolute;
        left: 20px;
        top: 100%;
        right: 20px;
        border-bottom: 1px solid #e9e9e9;
    }

    .global_setting .tabs_content .section_content.show:after,
    .global_setting .tabs_content .section_content:last-of-type:after,
    .global_setting .tabs_content .section_content.open_area:after {
        border: 0;
    }

    .global_setting .tabs_content .section_content .toggle_content {
        max-height: 0;
        overflow: hidden;
        animation: smoothSlidedown 0.3s;
        animation-timing-function: cubic-bezier(1.0, 0.5);
    }

    .global_setting .tabs_content .section_content.show .toggle_content {
        max-height: 1000vh;
        animation: smoothSlideup 0.8s;
        animation-timing-function: cubic-bezier(1.0, 0.5);
    }

    .global_setting .tabs_content .section_content.show .toggle_section i {
        transform: rotate(-180deg);
    }

    /* --- */

    .section_content .section_header .status {
        font-size: 13px;
        line-height: 18px;
        font-weight: 500;
        margin: 0 0 0 auto;
        flex-grow: 0;
    }

    .section_content .section_header .switch_option {
        flex: 1 1 auto;
    }

    .section_header h2 span {
        font-size: 13px;
        line-height: 17px;
        color: #f2a31d;
        display: block;
        padding-top: 7px;
    }

    @media(max-width: 1499px) {
        .time_selection .form_grp {
            flex: 0 0 100%;
            width: 100%;
        }

        .selection_box {
            font-size: 11px;
            line-height: 15px;
        }

        .time_listing li {
            padding: 3px;
        }

        .tab_sec li {
            font-size: 12px;
            line-height: 15px;
            padding: 8px 12px;
        }
    }

    :deep(.weekdays .multiselect-single-label-text) {
        text-transform: capitalize;
    }

    :deep(.question_list) {
        display: block;
        margin: 0;
    }

    :deep(.question_list li) {
        width: 100%;
    }

    :deep(.question_list li h5 label) {
        margin-right: auto;
        margin-left: 0;
        padding-left: 0;
    }

    .grp_wpr.grp_option {
        display: flex;
        flex-wrap: wrap;
    }

    .box_item {
        flex: 1 0 calc(50% - 10px);
    }

    .box_item label {
        border: 1px solid #CBCDD3;
        border-radius: 6px;
        padding: 5px 7px;
        display: flex;
        align-items: center;
    }

    .mrl-5 {
        margin: 0 -5px;
    }

    .p-10-15 {
        padding: 10px 15px !important;
    }

    :deep(.dp__input_wrap input) {
        font-size: 12px;
        color: #5a5a5a;
    }

    .tab_slider {
        margin: 0 -15px;
    }

    :deep(.dp__main .dp__input) {
        font-size: 11px;
        max-width: 120px;
        padding: 0 0 0 35px !important;
    }

    .addblock_section {
        position: relative;
        width: 100%;
        padding: 0 10px 20px 10px;
    }

    .addblock_section .add_types {
        max-width: 572px;
        width: 100%;
        position: absolute;
        top: 100%;
        left: 50%;
        transform: translateX(-50%);
        background: #fff;
        border: 1px solid #e9e9e9;
        border-radius: 8px;
        box-shadow: 5px 50px 50px rgba(0, 0, 0, .1);
        text-align: center;
        z-index: 2;
    }

    .addblock_section .add_types:after {
        position: absolute;
        content: '';
        background-image: url("data:image/svg+xml,%3Csvg id='Layer_1' data-name='Layer 1' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 262.83 148.44'%3E%3Cdefs%3E%3Cstyle%3E.cls-1%7Bfill:%23fff;stroke:%23c7c6c6;stroke-miterlimit:10;stroke-width:7px;%7D%3C/style%3E%3C/defs%3E%3Cpolyline class='cls-1' points='2.58 2.36 131.41 143.25 260.24 2.36'/%3E%3C/svg%3E");
        background-repeat: no-repeat;
        text-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
        width: 20px;
        height: 13px;
        left: calc(50% - 10px);
        bottom: 100%;
        transform: scale(-1);
    }

    .addblock_section .add_types ul {
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        gap: 10px;
        padding: 30px 20px;
    }

    .addblock_section .add_types ul li {
        flex: 0 1 80px;
    }

    .addblock_section .add_types ul li .card_item {
        padding: 15px 5px;
    }

    .addblock_section .add_types .title {
        padding: 15px 30px;
        text-align: center;
        border-bottom: 1px solid #f5f5f5;
        font-size: 13px;
        line-height: 17px;
        font-weight: 400;
        color: #121525;
    }

    .block_list .block_actions {
        display: flex;
        align-items: center;
        gap: 15px;
    }

    .block_list .block_actions button {
        font-size: 15px;
        color: #121525;
        cursor: pointer;
    }

    .block_list .block_actions button.up_down {
        color: #999;
    }

    .block_list .block_actions .block_setting {
        position: relative;
        cursor: pointer;
    }

    .block_list .block_actions .block_setting i {
        font-size: 15px;
        color: #999;
    }

    .block_list .block_actions .block_setting .dropdown_wpr {
        min-width: 250px;
        max-height: 350px;
        overflow-y: scroll;
        right: 1px;
        left: auto;
        padding: 15px;
        margin-bottom: 10px;
        z-index: 4;
    }

    .block_list .block_actions .block_setting .dropdown_wpr::-webkit-scrollbar {
        display: none;
    }

    .block_list .block_actions .block_setting .dropdown_wpr:before {
        display: none;
    }

    .block_list .block_actions .block_setting .dropdown_wpr .sec_label {
        font-size: 13px;
        line-height: 16px;
        font-weight: 500;
        color: #121525;
        padding: 3px 0;
        display: block;
    }

    .block_list .block_actions .block_setting .dropdown_wpr ul li {
        padding-top: 13px;
        border: 0;
        background: #f5f5f5;
        font-size: 12px;
        line-height: 15px;
        font-weight: 500;
        margin-bottom: 10px;
        border-radius: 5px;
    }

    .block_list .block_actions .block_setting .dropdown_wpr ul li .switch_option h5 {
        font-weight: 400;
    }

    .block_list .block_actions .block_setting .dropdown_wpr .group_item .input_label {
        font-size: 11px;
        line-height: 14px;
        color: #5a5a5a;
        font-weight: 400;
    }

    .block_list .block_actions .block_setting .dropdown_wpr .field_wpr,
    .block_list .block_actions .block_setting .dropdown_wpr .form_grp .multiselect {
        background: #fff;
    }

    .block_list .block_actions .block_setting .dropdown_wpr .field_wpr input,
    .block_list .block_actions .block_setting .dropdown_wpr .form_grp .multiselect {
        height: 30px;
        min-height: 30px;
        font-size: 11px;
    }

    .block_list .block_actions .block_setting .dropdown_wpr .field_wpr.has_suffix {
        padding-right: 40px;
    }

    .block_list .block_actions .block_setting .dropdown_wpr .field_wpr.has_suffix .suffix {
        width: 40px;
        font-size: 11px;
        line-height: 14px;
        background: #f5f5f5;
        border-radius: 0 5px 5px 0;
    }

    .block_list .block_actions .block_setting .dropdown_wpr .form_grp :deep(.multiselect .multiselect-dropdown) {
        min-width: 100%;
    }

    .block_list .block_actions .block_setting .dropdown_wpr .form_grp :deep(.multiselect .multiselect-dropdown ul.multiselect-options li) {
        padding: 5px 10px;
        font-size: 11px;
        line-height: 15px;
    }

    .block_list li .block_panel {
        border: 1px solid #eaeaea;
        background: #f5f5f5;
        border-radius: 6px;
        padding: 30px;
        margin-bottom: 40px;
        transition: all 0.3s ease-in-out;
    }

    .block_list li .block_panel.active {
        border: 1px solid #c8dfff;
        background: #fbfbfb;
    }

    .block_panel li.add_item button {
        width: 150px;
        border-radius: 6px;
        background: #fff;
        border: 1px solid #f5f5f5;
        padding: 20px 10px;
        min-height: 134px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        align-self: flex-start;
        font-size: 13px;
        line-height: 18px;
        gap: 10px;
        color: #999;
        cursor: pointer;
    }

    .block_library {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 30px 30px 0 30px;
        max-height: calc(100dvh - 84px);
        height: 100%;
        /* overflow-y: scroll; */
    }
    .block_library::-webkit-scrollbar{
        width: 8px;
    }
    .block_library::-webkit-scrollbar-thumb{
        background: #dbdbdb;
        border-radius: 4px;
    }

    .block_library ul.list {
        display: flex;
        flex-wrap: wrap;
        margin: 10px -30px 0 -30px;
        padding: 0 20px;
        /* width: 100%; */
    }

    .block_library ul.list.shake {
        animation: headShake 0.3s linear 0s infinite alternate;
    }

    .block_library ul.list>li {
        padding: 20px 10px;
        width: 33.333%;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 10px;
        position: relative;
    }

    .video_wpr {
        position: relative;
        width: 100%;
        padding-bottom: 56.25%;
        margin: 0;
        border-radius: 5px;
        overflow: hidden;
    }

    .video_wpr iframe {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border-radius: 3px;
    }

    .block_library ul li h6 {
        font-size: 11px;
        line-height: 14px;
        color: #5a5a5a;
        font-weight: 500;
        text-align: center;
    }
    .block_library ul li h6 label{
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        overflow: hidden;
    }

    .block_library ul li .action_items {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        position: absolute;
        left: 0;
        top: 0;
        padding: 0 10px;
        opacity: 0;
        transition: all 0.3s ease-in-out;
    }

    .block_library ul li:hover .action_items,
    .block_library ul li.selected .action_items,
    .block_library ul.target li .action_items,
    .block_library ul li .action_items.active {
        opacity: 1;
    }

    .block_library ul li .action_items label.checkbox span {
        width: 12px;
        flex: 0 0 12px;
        height: 12px;
        border-radius: 2px;
    }

    .block_library ul li .action_items label.checkbox span i {
        font-size: 8px;
    }

    .block_library ul li .action_items a {
        cursor: pointer;
        font-size: 11px;
        line-height: 14px;
        color: #5a5a5a;
    }

    .block_library ul li .action_items a i {
        font-size: 9px;
        padding-right: 4px;
    }

    .list_footer{
        width: 100%;
    }
    .list_footer ul li {
        font-size: 11px;
        line-height: 14px;
    }
    :deep(.pagination){
        width: 100%;
    }

    .block_library .library_header {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 20px;
    }

    .block_library .library_header a {
        font-size: 11px;
        line-height: 14px;
        font-weight: 400;
        color: #5a5a5a;
        cursor: pointer;
    }
    button.favorite{
        cursor: pointer;
    }
    .block_library .filter_search {
        height: 34px;
        border-radius: 20px;
        padding: 0 34px 0 0;
        position: relative;
        background: #fbfbfb;
        max-width: 400px;
        flex: 1 1 auto;
    }

    .block_library .filter_search input {
        font-size: 11px;
        line-height: 34px;
        color: #666;
        border: 0;
        width: 100%;
        padding: 0 15px;
        background: transparent;
        box-sizing: border-box;
    }

    .block_library .filter_search .search_btn {
        width: 34px;
        height: 34px;
        border-radius: 50%;
        border: 0;
        font-size: 11px;
        color: #333;
        position: absolute;
        right: 0;
        top: 0;
        cursor: pointer;
    }

    .block_library .filters {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 20px;
        margin: 25px 0 10px 0;
        position: relative;
        z-index: 2;
    }

    .block_library .filters h5 {
        font-size: 13px;
        line-height: 18px;
        color: #121525;
        font-weight: 400;
    }

    .other_filter_items {
        width: 20px;
        height: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
        background: #f1f1f1;
        border-radius: 50%;
        font-size: 10px;
        position: relative;
        cursor: pointer;
    }

    .attribute_bar {
        padding: 15px 0;
        background: #fff;
        border-left: 1px solid #e9e9e9;
        width: 230px;
        max-height: 100vh;
        overflow-y: overlay;
        position: relative;
        margin-right: -230px;
        transition: all 0.3s ease-in-out;
        display: flex;
        flex-direction: column;
    }

    .attribute_bar.show {
        margin-right: 0;
    }

    .attribute_bar::-webkit-scrollbar {
        width: 4px;
    }

    .attribute_bar::-webkit-scrollbar-thumb {
        border-radius: 2px;
        background: #cacaca;
    }

    .attribute_bar h3 {
        font-size: 15px;
        line-height: 20px;
        color: #121525;
        font-weight: 500;
        padding: 0 20px 5px 20px;
        display: flex;
        align-items: center;
    }

    .attribute_bar h3 button {
        font-size: 13px;
        color: #999;
        margin-left: auto;
        cursor: pointer;
    }

    .toggle_btn {
        display: flex;
        justify-content: flex-end;
        padding: 6px 20px 12px 20px;
    }

    .toggle_btn a {
        padding: 4px 10px;
        background: #f5f5f5;
        font-size: 10px;
        line-height: 12px;
        color: #121525;
        font-weight: 500;
        display: block;
        border-radius: 10px;
        cursor: pointer;
    }

    .attribute_grp {
        border-top: 1px solid #f5f5f5;
    }

    .attribute_grp h4 {
        padding: 12px 15px;
        font-size: 13px;
        line-height: 20px;
        color: #121525;
        font-weight: 500;
        display: flex;
        align-items: center;
        cursor: pointer;
    }

    .attribute_grp h4 a {
        color: #5a5a5a;
        margin-right: 15px;
        font-size: 11px;
        display: flex;
        align-items: center;
    }

    .attribute_grp h4 a.pinned {
        color: #2f7eed;
    }

    .attribute_grp h4>i {
        margin-left: auto;
        font-size: 11px;
        color: #999;
        transition: all 0.3s ease-in-out;
    }

    .attribute_grp ul {
        padding: 0 15px;
        max-height: 0;
        overflow: hidden;
        transition: all 0.3s ease-in-out;
    }

    .attribute_grp.active ul {
        max-height: 10000px;
    }

    .attribute_grp.active h4>i {
        transform: rotate(-180deg);
    }

    .attribute_bar ul li {
        font-size: 13px;
        line-height: 18px;
        color: #5a5a5a;
        font-weight: 400;
        padding: 10px 15px;
    }

    .attribute_bar ul li:last-child {
        margin-bottom: 15px;
    }

    .attribute_bar ul li label.checkbox,
    .dropdown_wpr ul li label.checkbox {
        justify-content: flex-start;
        gap: 10px;
        cursor: pointer;
    }

    .add_attribute {
        font-size: 12px;
        line-height: 16px;
        font-weight: 400;
        padding: 10px 20px;
        background: #2f7eed;
        color: #fff;
        border-radius: 5px;
        margin: auto 20px 0 20px;
        display: flex;
        align-items: center;
        justify-content: center;
        text-transform: uppercase;
        letter-spacing: 0.5px;
        gap: 7px;
        cursor: pointer;
    }

    .add_attribute i {
        font-size: 9px;
    }

    .filters .attribute_bar {
        position: fixed;
        right: -230px;
        top: 0;
        height: 100vh;
        overflow-y: scroll;
        transition: all 0.3s ease-in-out;
    }

    .filters .attribute_bar.show {
        right: 0;
    }

    .search_tags {
        background: #fbfbfb;
        border-radius: 4px;
        padding: 10px;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        gap: 8px;
        margin-top: 20px;
    }

    .search_tags li {
        background: #fff;
        border: 1px solid #d9d9d9;
        border-radius: 4px;
        padding: 4px 10px;
        font-size: 11px;
        line-height: 14px;
        font-weight: 400;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #5a5a5a;
        position: relative;
    }

    .search_tags .delete_btn {
        width: 12px;
        height: 12px;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 8px;
        background: #eb1414;
        color: #fff;
        position: absolute;
        right: -5px;
        top: -5px;
        cursor: pointer;
        opacity: 0;
        transition: all 0.3s ease-in-out;
    }

    .search_tags li:hover .delete_btn {
        opacity: 1;
    }

    .dropdown_wpr ul li label.checkbox span i {
        margin: 0;
    }

    .attribute_bar ul li label.checkbox span.active i,
    .dropdown_wpr ul li label.checkbox span.active i {
        transform: scale(1);
        opacity: 1;
    }

    .bulk_action {
        border-right: 1px solid #d9d9d9;
        padding-right: 20px;
        display: flex;
        align-items: center;
        gap: 15px;
    }

    .bulk_action .delete_btn {
        color: #eb1414;
        cursor: pointer;
    }

    .filters .bulk_action .filter_item,
    .filters .bulk_action .filter_item i {
        color: #2f7eed;
    }

    .filter_item i {
        font-size: 9px;
        padding: 5px;
        vertical-align: top;
    }

    .filters .filter_item i,
    .attribute_list i {
        color: #7a7a7a;
        pointer-events: none;
    }

    .filters .filter_item .dropdown_wpr,
    .attribute_list .dropdown_wpr {
        min-width: 160px;
        overflow-y: hidden;
    }

    .filters .filter_item .dropdown_wpr ul,
    .attribute_list .dropdown_wpr ul {
        max-height: 400px;
        overflow-y: scroll;
    }

    .attribute_list .dropdown_wpr ul {
        max-height: 200px;
        overflow-y: scroll;
    }

    .filters .filter_item .dropdown_wpr ul::-webkit-scrollbar,
    .other_filter_items .dropdown_wpr::-webkit-scrollbar,
    .attribute_list .dropdown_wpr ul::-webkit-scrollbar,
    .attribute_list .dropdown_wpr ul::-webkit-scrollbar {
        display: none;
    }

    .filters .filter_item .dropdown_wpr ul li,
    .other_filter_items .dropdown_wpr ul li,
    .attribute_list .dropdown_wpr ul li {
        border: 0;
    }

    .filters .filter_item .dropdown_wpr ul li.active {
        background: #f5f5f5;
    }

    .dropdown_wpr ul li.dropdown-header {
        background: #e9e9e9;
    }

    .att_tags .delete_btn {
        width: 12px;
        height: 12px;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 8px;
        background: #eb1414;
        color: #fff;
        position: absolute;
        right: -5px;
        top: -5px;
        cursor: pointer;
        opacity: 0;
        transition: all 0.3s ease-in-out;
    }

    .att_tags li.exercise,
    .search_tags li.exercise {
        border-color: #88d3d9;
    }

    .att_tags li.position,
    .search_tags li.position {
        border-color: #b99191;
    }

    .att_tags li.contraction,
    .search_tags li.contraction {
        border-color: #968bca;
    }

    .att_tags li.muscle,
    .search_tags li.muscle {
        border-color: #76afff;
    }

    .att_tags li.movement,
    .search_tags li.movement {
        border-color: #cb72ff;
    }

    .att_tags li.equipment,
    .search_tags li.equipment {
        border-color: #56e094;
    }

    .att_tags li.skeletal,
    .search_tags li.skeletal {
        border-color: #fb76c8;
    }

    .att_tags li.difficulty,
    .search_tags li.difficulty {
        border-color: #f2a31d;
    }
    .global_setting .section_header h2 {
        display: flex;
        align-items: center;
        gap: 10px;
    }

    .global_setting .section_header h2 input {
        font-size: 16px;
        line-height: 20px;
        background: transparent;
        width: 200px;
        background: #f0f5ff;
        padding: 10px 15px;
        border-radius: 5px;
    }

    .global_setting .section_header h2 .save_btn {
        padding: 3px 8px;
        background: #2f7eed;
        color: #fff;
        border-radius: 3px;
        font-size: 8px;
        line-height: 10px;
        text-transform: uppercase;
        cursor: pointer;
    }

    .load_more {
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 13px;
        line-height: 18px;
        color: #999;
        gap: 10px;
    }

    .video-overlay {
        position: absolute;
        z-index: 1;
        height: 100%;
        width: 100%;
        top: 0;
        cursor: pointer;
        border: 1px solid #e9e9e9;
        border-radius: 5px;
    }

    .filters .load_more {
        margin-top: 0px;
    }
    .filters .favorite{
        display: none;
    }
    .attribute_bar ul li {
        display: flex;
        justify-content: space-between;
    }

    .block_library .filters {
        flex-wrap: wrap;
    }

    #workout-wizard-preview {
        overflow: hidden;
    }

    #exercises-container {
        height: auto;
        /* height: 60vh; */
        overflow-y: scroll;
        -ms-overflow-style: none;
        scrollbar-width: none;
    }

    /* Hide scrollbar for Chrome, Safari and Opera */
    #exercises-container::-webkit-scrollbar {
        width: 8px;
    }
    #exercises-container::-webkit-scrollbar-thumb{
        background: #dbdbdb;
        border-radius: 4px;
    }

    @media(max-width: 1399px){
        .block_library .filters{
            gap: 10px;
        }
        .block_library .library_header{
            gap: 10px;
        }
        .block_library ul.list>li{
            width: 50%;
        }
    }
    @media(max-width: 991px){
        .block_library{
            padding: 30px 20px 0 20px;
        }
        .block_library ul.list>li{
            width: 100%;
        }
        .block_library .filters{
            width: 100%;
        }
        .filters .filter_item.pinned-type{
            display: none;
        }
        .block_library .library_header .favorite{
            display: none;
        }
        .filters .favorite{
            display: block;
            margin-left: auto;
        }
        .block_library .library_header a{
            white-space: nowrap;
            width: 30px;
            overflow: hidden;
        }
        .search_tags{
            gap: 4px;
        }
        .search_tags li{
            padding: 3px 6px;
            font-size: 9px;
            line-height: 12px;
        }
    }

    :deep(.exercise-quote-loader .quote_wpr) {
        width: 100%;
        height: 60vh;
        display: flex;
        align-items: center;
        justify-content: center;
    }
</style>
